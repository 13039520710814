import { request as axios } from '@/util/request';
const getUrl = require('bmh/url-with-param');

const API = {
  version_data: '/boss/setting/app/version',
};
// 获取列表
export async function get_version(params: any) { 
  const Url = getUrl(API.version_data,params)
  return await axios.get(Url);
}
// 新建版本
export async function post_version(params: any) {
  return await axios.post(API.version_data,params);
}
// 编辑版本
export async function put_version(params: any) {
  return await axios.put(API.version_data+`/${params.id}`,params);
}