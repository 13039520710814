
import { Component ,Mixins } from 'vue-property-decorator';
import { get_version , post_version ,put_version} from '@/api/setting/version'
import { changeLoading } from '@/util/decorators';
import { getValidatorMap, Validate, Validator } from '@/mixin/validator';

import moment from 'moment';
// 搜索
interface SearchType {
    app_id: number;
    version_number: string;
    upgrade_type: number|string;
    creator: string;
    update_time: any;
    client: number;
}
// 组件
import CustomModal from '@/component/custom-modal.vue';
@Component({
    name:'Versions',
    components: {
        CustomModal,
    },
})
export default class Versions extends Mixins(Validate){
    visible: boolean = false
    is_loading: boolean = false;
    current: number = 1
    title: string = ''
    dis: boolean = false
    searchList: SearchType = {
        app_id : 0,
        version_number:"",
        upgrade_type:"",
        creator:"",
        update_time:"",
        client:0
    }
    status_list: any =[
        {
            value : 0,
            label : '全部'
        },
        {
            value : 1,
            label : 'ios'
        },
        {
            value : 2,
            label : '安卓'
        },
        {
            value : 3,
            label : 'macos'
        },
        {
            value : 4,
            label : 'windows'
        },
    ]
    form: any = {
        status :true,
        app_id : 1,
        client : 1,
        package_url:"",
        version_number:"",
        build_number:"",
        upgrade_desc:"",
        upgrade_type:1,
        notify_count:0,
        env:0,
        compatible_version_number:"",
        compatible_build_number:""
    }
    validator_list: any = [
        {
            field : "app_id",
            message: '请输入APP名称',
        },
        {
            field : "client",
            message:"请输入客户端"
        },
        {
            field : "package_url",
            message:"请输入安装包地址"
        },
        {
            field : "version_number",
            message:"请输入版本号"
        },
        {
            field : "build_number",
            message:"请输入构建号"
        },
        {
            field : "upgrade_desc",
            message:"请输入升级描述语"
        },
        {
            field : "upgrade_type",
            message:"请输入升级类型"
        },
    ]
    validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);

    columns: any = [
        {
            title:'APP名称',
            dataIndex:'app.name',
            width:150,
        },
        {
            title:'客户端',
            dataIndex:'client',
            scopedSlots: { customRender: 'client' },
            width:100
        },
        {
            title:'版本号',
            dataIndex:'version_number',
            width:100
        },
        {
            title:'构建号',
            dataIndex:'build_number',
            width:100
        },
        {
            title:'升级类型',
            dataIndex:'upgrade_type',
            scopedSlots: { customRender: 'upgrade_type' },
            width:150
        },
        {
            title:'版本状态',
            dataIndex:'env',
            scopedSlots: { customRender: 'env' },
            width:200
        },
        {
            title:'兼容版本',
            dataIndex:'compatible_version_number',
            width:200
        },
        {
            title:'更新描述',
            dataIndex:'upgrade_desc',
            width:200
        },
        {
            title:'安装地址',
            dataIndex:'package_url',
            width:400
        },
        {
            title:'创建人',
            dataIndex:'creator',
            width:200
        },
        {
            title:'更新时间',
            dataIndex:'update_time',
            width:200
        },
        {
            title:'操作',
            dataIndex:'operation',
            scopedSlots: { customRender: 'operation' },
            width:200,
            fixed:'right'
        },
    ]
    version_data: any ={}
    layout ={
        labelCol: { span: 6 },
    }
    // 客户端切换
    async query(){
        await this.search(1)
    }
    // 分页
    onTableChange(pagination: any){
        this.current = pagination.current
        this.search()
    }
    // 分页显示
    paginationOption(data: RemotePagination<BannerTableData>) {
        return {
            total: data.total || 0,
            showTotal: () => `共${data.total}条，每页${data.limit}条`,
            pageSize: data.limit || 1,
            current: Number(data.current) || 1,
        };
    }
    // 编辑版本
    redact(data: any){
        this.form = {
            ...data
        }
        if(data.status == 1){
            this.form.status = true
        }else{
            this.form.status = false
        }
        delete this.form.update_time
        delete this.form.app
        delete this.form.create_time
        this.visible = true ; 
        this.title = '编辑版本'
        this.dis = true
    }
    // 客户端改变
    clientChange(){
        this.form.env = 0
        this.form.compatible_version_number = ""
    }
    // 搜索
    @changeLoading(['is_loading'])
    async search(current?: number ){
        if(current) this.current = current
        const start =this.searchList.update_time[0]? new Date(moment(this.searchList.update_time[0]).format("YYYY-MM-DD")+ " 00:00:00").getTime()/1000:""
        const end = this.searchList.update_time[0]? new Date(moment(this.searchList.update_time[1]).format("YYYY-MM-DD")+ " 23:59:59").getTime()/1000:""
        const data: any= {
            ...this.searchList
        }
        delete data.update_time
        start ? data.update_time_start = start :null
        end ? data.update_time_end = end:null
        await this.get_version(data)
    }
    // 清空搜索条件
    clearSearch(){
        this.searchList = {
            app_id : 0,
            version_number:"",
            upgrade_type:"",
            creator:"",
            update_time:"",
            client: 0
        }
        this.search(1)
    }
    // 清空表单
    clearForm(){
        this.form = {
            app_id : 1,
            client : 1,
            package_url:"",
            version_number:"",
            build_number:"",
            upgrade_desc:"",
            upgrade_type:1,
            notify_count:0,
            env:0,
            compatible_version_number:""
        }
    }
    // 关闭modal
    handleCancel(){
        this.visible = false
        this.clearForm()
    }
    // 确认
    async handleOk(){
        if (!this.validateCommit() ||  ( this.form.client == 2 && this.form.env == 0)) {
            this.$message.error("必填项未填写")
            return;
        }
        const data = this.form
        if(data.status){
            data.status = 1
        }else{
            data.status = 2
        }
        let res 
        if(this.title === "新建版本"){
            res = await post_version(data)
        }else{
            res = await put_version(data)
        }
        if(res.status!==200){
            this.$message.error((res as any).message)
            return
        }
        await this.search()
        this.clearForm()
        this.visible = false
    }
    // 获取列表请求
    async get_version( body?: any){
        const data = {
            ...body,
            current:this.current
        } 
        const res = await get_version(data)
        res.data.detail.forEach((item: any,index: any)=>{
            res.data.detail[index].update_time = moment(item.update_time * 1000).format("YYYY-MM-DD HH:mm:ss")
        })
        this.version_data = res.data
    }
    @changeLoading(['is_loading'])
    async created(){
        await this.get_version()
    }
}
